/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// AUTOGENERATED BY glean_parser v8.1.1. DO NOT EDIT. DO NOT COMMIT.

import PingType from '@mozilla/glean/private/ping';

/**
 * An account event
 *
 * Generated from `accounts-events`.
 */
export const accountsEvents = new PingType({
  name: 'accounts-events',
  includeClientId: true,
  sendIfEmpty: false,
  reasonCodes: [],
});
