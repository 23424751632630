/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// AUTOGENERATED BY glean_parser v8.1.1. DO NOT EDIT. DO NOT COMMIT.

import StringMetricType from '@mozilla/glean/private/metrics/string';

/**
 * The client id of the relying party
 *
 * Generated from `relying_party.oauth_client_id`.
 */
export const oauthClientId = new StringMetricType({
  category: 'relying_party',
  name: 'oauth_client_id',
  sendInPings: ['accounts-events'],
  lifetime: 'ping',
  disabled: false,
});

/**
 * The service name of the relying party
 *
 * Generated from `relying_party.service`.
 */
export const service = new StringMetricType({
  category: 'relying_party',
  name: 'service',
  sendInPings: ['accounts-events'],
  lifetime: 'ping',
  disabled: false,
});
